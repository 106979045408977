.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-height: 81vh;
}

.card {
    padding: 1rem 1.2rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
}

.card span {
    display: inline-block;
    transition: transform 200ms;
}

.card h2 {
    font-weight: 600;
    margin-bottom: 0.7rem;
}

.card p {
    margin: 0;
    opacity: 0.6;
    font-size: 0.9rem;
    line-height: 1.5;
    max-width: 30ch;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
}

.center::before {
    background: var(--secondary-glow);
    border-radius: 50%;
    width: 480px;
    height: 50px;
    margin-left: -400px;
}

.center::after {
    background: var(--primary-glow);
    width: 240px;
    height: 50px;
    z-index: -1;
}

.center::before,
.center::after {
    content: '';
    left: 50%;
    position: absolute;
    filter: blur(45px);
    transform: translateZ(0);
}

/* .logo {
    
} */

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
    .card:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }

    .card:hover span {
        transform: translateX(4px);
    }
}

@media (prefers-reduced-motion) {
    .card:hover span {
        transform: none;
    }
}

/* Mobile */
@media (max-width: 700px) {
    .content {
        padding: 4rem;
    }

    .card {
        padding: 1rem 2.5rem;
    }

    .card h2 {
        margin-bottom: 0.5rem;
    }

    .center::before {
        transform: none;
        height: 50px;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
    .grid {
        grid-template-columns: repeat(2, 50%);
    }
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}